import { ArrowRightIcon } from '@heroicons/react/24/outline'
import React from 'react'
import AboutMainImage from "../../asset/aboutImage.png"
import ReactWhatsapp from 'react-whatsapp'

const AboutHome = () => {
  return (
    <div id='#about' className='grid w-full grid-cols-1 lg:grid-cols-3 grid-flow-row' >
       <div className='col-span-2 xl:px-[120px] lg:px-[60px] px-[20px] py-12 lg:py-0 flex items-start justify-center flex-col bg-[#f7dfd3]' >
          <h4 className='text-[1.2rem] font-[GilroyMedium]  text-gray-700' >About Us</h4>
          <h2 className='lg:text-[3.3rem] leading-[2.3rem] lg:leading-[3.5rem] text-[2.3rem] mb-6 font-[CormorantBold]'>App Se Aap Tak—Your Vision, Our Connections.</h2>
          <p className='font-[GilroyRegular] text-[.8rem] lg:text-[1.1rem] ' >At weddin.in, we are revolutionizing the way weddings and events are organized in India. Our platform serves as a one-stop destination where you can effortlessly book all the essential services for a perfect celebration. From décor and catering to entertainment and photography, we connect you with trusted vendors to ensure your wedding or event is seamless, stress-free, and unforgettable.
      <br />  <br />
Our mission is to become India’s most trusted and reliable event and wedding chain. With us, you can focus on creating cherished memories while we help you find the right services to bring your vision to life.</p>
<ReactWhatsapp number="9326429854" message="Hii, I want to Enquire About the Service" > <button className='text-[GilroyRegular] px-6 lg:px-12 text-[1rem] lg:text-[1.2rem] mt-6 py-3 lg:py-4 bg-[#241a10] text-white rounded-md' >Enquire now</button></ReactWhatsapp>
       </div>
       <div className='col-span-1' >
        <img src={AboutMainImage} />
       </div>
    </div>
  )
}

export default AboutHome