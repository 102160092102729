import React from 'react'
import Logo from "../../asset/logo.png"
import { IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin, IoMdMail } from "react-icons/io";
import { HiLocationMarker } from "react-icons/hi";
import { IoCall } from "react-icons/io5";
const Footer = () => {
  return (
    <div>
    <div className='bg-[#E9CFC2]  flex items-center justify-center flex-col py-8 px-6'>
        <img className='lg:w-[250px] w-[200px]' src={Logo} />
        {/* <div>
            <p className='lg:text-center mt-3 mb-8 text-white text-[.9rem] lg:text-[1.2rem]'>industroz Industry has very good strength in innovative technology <br /> and tools with over 35 years of experience.</p>
        </div>
        <div  >
            <p className='lg:text-center text-white my-3 text-[.6rem] lg:text-[.8rem] flex items-center justify-center gap-3'><HiLocationMarker className='lg:text-[2.5rem] text-[3rem] text-[#99821d]' />Survey No 16/1, Laxmi Baug N.H-8 Opp Dara's dhaba, <br className='hidden lg:block' /> Kashimira Mira Road (E),Thane 401107, India.</p>
           
        </div> */}
        <div className='flex items-center justify-center gap-6 mt-3' >
          <a href='https://www.facebook.com/profile.php?id=61568095564581'><IoLogoFacebook className='lg:text-[2rem] text-[1.4rem] text-[#241a10] ' /></a>
         <a href='https://www.instagram.com/weddin.in/'> <IoLogoInstagram className='lg:text-[2rem] text-[1.4rem]  text-[#241a10] ' /></a>
         <a href='https://www.linkedin.com/company/weddin-in/about/'> <IoLogoLinkedin className='lg:text-[2rem] text-[1.4rem] text-[#241a10]' /></a>
        </div>
        <div className='flex flex-row items-center lg:items-center my-6 justify-center gap-6 lg:gap-12'>
      <a href="mailto:support@weddin.in">  <p className='lg:text-center text-[.7rem] lg:text-[1rem] text-[#241a10] flex items-center justify-center gap-3'><IoMdMail className='lg:text-[1.4rem] text-[1rem] text-[#241a10]' />support@weddin.in</p></a>
     <a href="tel:9326429854"> <p className='lg:text-center text-[.7rem] lg:text-[1rem] text-[#241a10] flex items-center justify-center gap-3'><IoCall className='lg:text-[1.4rem] text-[1rem] text-[#241a10]' />+91 93264 29854</p></a>
        </div>

    </div>
    <div className='bg-[#241a10] text-white text-[.9rem] lg:text-[1.1rem] py-2 px-12 text-center'>
    <p> © Copyright  Weddin 2024.</p>
    </div>
    </div>
  )
}

export default Footer