import { ArrowRightIcon } from '@heroicons/react/24/outline'
import React from 'react'
import AboutMainImage from "../../asset/ctaImage.png"
import ReactWhatsapp from 'react-whatsapp'

const CTA = () => {
  return (
    <div id='#about' className='grid w-full grid-cols-1 lg:grid-cols-3 grid-flow-row' >
        <div className='col-span-1' >
        <img src={AboutMainImage} />
       </div>
       <div className='col-span-2 xl:px-[120px] lg:px-[60px] px-[20px] py-12 lg:py-0 flex items-start justify-center flex-col bg-[#ffffff]' >
         
          <h2 className='lg:text-[5rem] leading-[2.3rem] lg:leading-[3.5rem] text-[2.5rem] mb-6 font-[CormorantBold]'>Let’s Connect!</h2>
          <p className='font-[GilroyRegular] mt-3 text-[.8rem] lg:text-[1.1rem] ' >Are you planning an event or a vendor offering exceptional services? We’re here for you! At weddin.in, our platform caters to all types of events, not just weddings. You can effortlessly book a wide range of services for any occasion, while vendors can showcase their expertise to a larger audience. </p>
          <div className='flex items-center justify-start gap-6 mt-12' >
          <ReactWhatsapp number="9326429854" message="Hii, I want to Enquire About the Service" >  <button className='text-[GilroyRegular] px-6 lg:px-12 text-[.8rem] lg:text-[1.2rem]  py-3 lg:py-4 border-[#241a10] border-[2px] text-[#241a10] rounded-md' >Explore Services</button></ReactWhatsapp>
          <ReactWhatsapp number="9326429854" message="Hii, I am a Vendor." > <button className='text-[GilroyRegular] px-6 lg:px-12 text-[.8rem] lg:text-[1.2rem]  py-3 lg:py-4 border-[#241a10] border-[2px] bg-[#241a10] text-white rounded-md' >Join as Vendor</button> </ReactWhatsapp>
</div>
       </div>
       
    </div>
  )
}

export default CTA