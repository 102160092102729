import React from 'react'
import "./Home.css"
import ReactWhatsapp from 'react-whatsapp'
const Platform = () => {
  return (
    <div className='platformMainBox flex items-center flex-col px-6 lg:px-48 justify-center' >
        <h3 className='lg:text-[3.5rem] text-[2.3rem] text-center mb-3 font-[CormorantBold]' >Platform Coming Soon !</h3>
        <p className='text-center font-[GilroyRegular] text-[.8rem] lg:text-[1rem]' >We’re buzzing with excitement at weddin.in as we prepare to launch our groundbreaking platform! Imagine a place where every detail of your wedding and events comes together seamlessly—where finding the perfect vendors is just a click away! Our innovative platform is designed to simplify your planning process and enhance your celebration experience like never before. With a diverse range of trusted service providers at your fingertips, your dream event is about to become a reality! Join us on this thrilling journey, and stay tuned for the big reveal—your unforgettable events are just around the corner! </p>
        <ReactWhatsapp number="9326429854" message="Hii, I want to Enquire About the Service" ><button className='text-[GilroyRegular] px-6 lg:px-12 text-[1rem] lg:text-[1.2rem] mt-6 py-3 lg:py-4 bg-[#241a10] text-white rounded-md' >Enquire now</button></ReactWhatsapp>
    </div>
  )
}

export default Platform