
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { NavbarTwo } from './components/Layout/Navbar';
import Home from './pages/Home';

function App() {
  return (
    <div className="App">
     <Routes>
     <Route path="/" element={<Home />} />
     </Routes>
    </div>
  );
}

export default App;
