import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  MobileNav
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Logo from "../../asset/logo.png"
import { IoMdArrowRoundUp } from "react-icons/io";
import { Link } from "react-router-dom";
import "./Navbar.css"
import ReactWhatsapp from "react-whatsapp";
const navListMenuItems = [
  {
    title: "Case Studies",
    
  },
  {
    title: "Portfolio",
 
  },
  {
    title: "Blog",
   
  },
  {
    title: "Exhibition Center",
  
  },
  
];
const aboutListMenuItems = [
    {
        title: "Vision",
        btnClass:"subMenuText1"
        
      },
      {
        title: "Management",
         btnClass:"subMenuText1"
     
      },
      {
        title: "Infrastructure",
       btnClass:"subMenuText1"
      },
      {
        title: "Certificates",
      btnClass:"subMenuText1"
      },
    
  ];
  const productListMenuItems = [
    {
      title: "Components",
      btnClass:"subMenuText1"
      
    },
    {
      title: "Fabrication",
       btnClass:"subMenuText1"
   
    },
    {
      title: "Assembly",
     btnClass:"subMenuText1"
    },
    
    
  ];
  const clientListMenuItems = [
    {
        title: "Power Sector",
        btnClass:"subMenuText1"
        
      },
      {
        title: "Packaging Industry",
         btnClass:"subMenuText1"
     
      },
      {
        title: "Defence Sector",
       btnClass:"subMenuText1"
      },
      {
        title: "Dairy Industry",
      btnClass:"subMenuText1"
      },
    
  ];
  const careerListMenuItems = [
    {
        title: "Job Openings",
        btnClass:"subMenuText1"
        
      },
      {
        title: "Job Applications",
         btnClass:"subMenuText1"
     
      },
    
  ];
function NavListMenu({titleItem,list,btnClass}) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const renderItems = list.map(
    ({ icon, title, description, btnClass }, key) => (
      <a href="#" key={key}>
        <MenuItem className={btnClass}>
          <div>
            <p
            
             
              className=""
            >
              {title}
            </p>
            
          </div>
        </MenuItem>
      </a>
    ),
  );
 
  return (
    <React.Fragment>
      <Menu
      style={{backgroundColor:"none !important"}}
        open={isMenuOpen}
        handler={setIsMenuOpen}
       className="bg-none shadow-none"
        allowHover={true}
      >
        <MenuHandler  className="bg-transparent shadow-none">
          <Typography>
            <p
              className={btnClass}
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              {titleItem}
              
            </p>
          </Typography>
        </MenuHandler>
        <MenuList style={{backgroundColor:"none !important"}} className="hidden mainMenuItem  bg-none text-center shadow-none border-none max-w-screen-xl  lg:block">
          <ul className="grid grid-cols-1   bg-none grid-flow-row gap-y-2 text-center  outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}
export function NavbarTwo() {
  const [openNav, setOpenNav] = React.useState(false);
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}
 
  const navList = (
    <ul className="mb-0 mt-0 z-[999] flex flex-col font-[PoppinsRegular] gap-6 text-black  lg:mb-0 lg:mt-0 lg:justify-end lg:flex-row lg:items-center ">
    
    
      
      <Typography
       
        className="font-[GilroyMedium]"
      >
        <Link to="/products"  smooth scroll={el => scrollWithOffset(el)} className="">
         Home
         </Link>
      </Typography>
      <Typography
       
        className="font-[GilroyMedium]"
     >
       <Link to="/products"  smooth scroll={el => scrollWithOffset(el)} className="">
        About
        </Link>
     </Typography>
      <Typography

        className="font-[GilroyMedium]"
      >
       <Link to="#contact" smooth scroll={el => scrollWithOffset(el)} className="">
         Contact Us
         </Link>
      </Typography>
     
    </ul>
  );
 
  return (
    <Navbar className={` fixed    w-[100vw !important] navBarBg  shadow-none max-w-full    z-[999]  rounded-none  py-2 px-2 lg:px-6 xl:px-24 lg:py-3`}>
      <div className="  flex  justify-between items-center text-blue-gray-900">
       <Link className="col-span-1" to="/">
        <img className="w-[90px] lg:w-[100px] xl:w-[130px]" src={Logo} alt="" />
       </Link>
        {/* <div className="hidden lg:block  col-span-3 ">{navList}</div> */}
        <div className="col-span-1" >
        <ReactWhatsapp number="9326429854" message="Hii, I want to Enquire About the Service" ><button className='text-[GilroyRegular] lg:text-[1rem] text-[.8rem] px-6 py-2 lg:px-8 lg:py-3 bg-[#241a10] text-white rounded-md' >Enquire now</button></ReactWhatsapp> 
        </div> 
      </div>
      {/* <MobileNav open={openNav}>
        <div className="container  mx-0 p-0">
          {navList}
         
        </div>
      </MobileNav> */}
    </Navbar>
  );
}