import React from 'react'
import Hall from "../../asset/hall.png"
import Makeup from "../../asset/bridalmakeup.png"
import Catering from "../../asset/catering.png"
import decorator from "../../asset/decorator.png"
import Photography from "../../asset/photography.png"
import Vehicle from "../../asset/vehicle.png"
import Band from "../../asset/band.png"
import Singer from "../../asset/singer:dancer.png"
import Preist from "../../asset/preist.png"
import Card from "../../asset/marriagecard.png"
const Service = () => {
  return (
    <div className='xl:px-[70px] lg:px-[30px] px-[10px] xl:py-[80px] lg:py-[35px] py-[30px]  '>
        <h3 className='lg:text-[3.5rem] text-center text-[2.5rem]  font-[CormorantBold]' >Services We Provide</h3>
        <p className='capitalize font-[GilroyRegular] text-center text-[#a1a1a1]' >Explore Our Service and Choose the Right One For You</p>
        <div className='grid grid-cols-2 mt-8 lg:mt-16 gap-3 xl:gap-12 lg:grid-cols-3 xl:grid-cols-5 grid-flow-row' >
            <div className='flex items-center justify-center flex-col gap-3' >
                <img src={Hall} />
                <p>Marriage Hall</p>
            </div>
            <div className='flex items-center justify-center flex-col gap-3' >
                <img src={Makeup} />
                <p>Bridal Makeup</p>
            </div>
            <div className='flex items-center justify-center flex-col gap-3' >
                <img src={Catering} />
                <p>Catering Service</p>
            </div>
            <div className='flex items-center justify-center flex-col gap-3' >
                <img src={decorator} />
                <p>Decorators</p>
            </div>
            <div className='flex items-center justify-center flex-col gap-3' >
                <img src={Photography} />
                <p>Photography</p>
            </div>
            <div className='flex items-center justify-center flex-col gap-3' >
                <img src={Vehicle} />
                <p>Vehicles</p>
            </div>
            <div className='flex items-center justify-center flex-col gap-3' >
                <img src={Band} />
                <p>Band</p>
            </div>
            <div className='flex items-center justify-center flex-col gap-3' >
                <img src={Singer} />
                <p>Singer/ Dancer</p>
            </div>
            <div className='flex items-center justify-center flex-col gap-3' >
                <img src={Preist} />
                <p>Priest</p>
            </div>
            <div className='flex items-center justify-center flex-col gap-3' >
                <img src={Card} />
                <p>Marriage Card</p>
            </div>
        </div>
    </div>
  )
}

export default Service