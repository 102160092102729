import React from 'react'
import BannerImage from "../../asset/bannerImage.png"
import BannerTextImage from "../../asset/bannerText.png"
import ReactWhatsapp from 'react-whatsapp'
const Banner = () => {
  return (
    <div className='grid grid-cols-1 xl:px-[70px] lg:px-[30px] px-[10px] xl:py-[80px] lg:py-[35px] py-[30px] lg:grid-cols-2 grid-flow-row items-center justify-center gap-12 '>
       <div>
        <img src={BannerTextImage} />
        <h1 className='lg:text-[3.5rem] text-[2.5rem] mb-3 font-[CormorantBold]' >APP SE AAP TAK</h1>
        <p className='lg:text-[1.3rem] text-[1.1rem] my-3 font-[GilroyRegular] text-[#949292]'>Find everything you need to plan the perfect day
        We Create Memories</p>
        <p  className='my-4 font-[GilroyRegular] text-[.8rem] lg:text-[1.2rem] text-[#b2998a]' >"Elevate your celebrations with the right vendors at your <br /> fingertips."</p>
        <ReactWhatsapp number="9326429854" message="Hii, I want to Enquire About the Service" ><button className='text-[GilroyRegular] px-8 py-3 bg-[#241a10] text-white rounded-md' >Enquire now</button></ReactWhatsapp> 
       </div>
       <div>
        <img src={BannerImage} />
       </div>
    </div>
  )
}

export default Banner