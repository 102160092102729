import React from 'react'
import { NavbarTwo } from '../components/Layout/Navbar'
import Banner from '../components/Home/Banner'
import Service from '../components/Home/Service'
import AboutHome from '../components/Home/AboutHome'
import Platform from '../components/Home/Platform'
import CTA from '../components/Home/CTA'
import Footer from '../components/Layout/Footer'


const Home = () => {
  return (
    <div>
        <NavbarTwo />
        <div className='2xl:pt-[120px] pt-[80px] xl:pt-[110px] lg:pt-[100px]'>
        <Banner />
        <Service />
        <AboutHome />
        <Platform />
        <CTA />
        <Footer />
        </div>
    </div>
  )
}

export default Home